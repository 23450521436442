import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { siteDefaultLanguage } from '../../../config';
import Giveaways from '../../components/Page/Giveaways';
import SEO from '../../components/Seo';
import gawelPicture from './images/gawel.png';

const GiveawaysPage = ({ pageContext: { intl } }) => {
  const { language, originalPath } = intl;
  const title = 'Skinwallet Giveaway!';
  const description =
    'Wypełnij parę prostych zadań i zwiększ swoją szansę na zdobycie świetnych skinów do CS:GO!';

  const hero = {
    title,
    description,
    glemUrl: 'https://gleam.io/u6okY/gawel-x-skinwallet-giveaway',
    glemLoadingText: 'Wczytuję...',
    glemPlaceholderText: 'Gaweł x Skinwallet Giveaway',
  };
  const streamer = {
    title: 'Skinwallet i Gaweł łączą siły',
    description:
      'Gaweł dał się poznać na Twitchu jako zrównoważony i merytoryczny streamer. Śledź jego rozgrywki CS:GO, by zdobyć szansę na darmowe skiny!',
    image: (
      <LazyLoadImage src={gawelPicture} alt="Gaweł" width={400} height={400} />
    ),
  };
  const marketURL = 'https://t.skinwallet.com/2QAnG2v';

  return (
    <>
      <SEO
        lang="pl"
        title={title}
        description={description}
        pathname={originalPath}
        robots={language !== siteDefaultLanguage ? 'index, nofollow' : null}
      />
      <Giveaways
        lang="pl"
        hero={hero}
        streamer={streamer}
        marketURL={marketURL}
      />
    </>
  );
};

GiveawaysPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default GiveawaysPage;
